import axios from "axios";
import AuthService from "./authService";

export const axiosInstance = axios.create({
  url: window?.__RUNTIME_CONFIG__?.VITE_BASE_URL ?? "",
});
export const cancelAxios = axios.CancelToken;
const authService = new AuthService();

let busyApiCall = false;

// Add a request interceptor
axiosInstance.interceptors.request.use(
  function (config) {
    return config;
  },
  async function (error: any) {
    return await Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error: any) {
    if (error.response.status === 401) {
      if (!busyApiCall) {
        busyApiCall = true;
        setTimeout(() => {
          window.dispatchEvent(
            new CustomEvent("PERMISSION_CHANGED", { detail: "SIGN_IN" })
          );
        }, 1000);
        setTimeout(() => {
          authService.logout();
        }, 1500);
      }
    }
    return await Promise.reject(error);
  }
);
