// import { isEmpty } from "lodash";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
// import ToastComponent from "../components/toast";
import { selectToken } from "../pages/auth/authSlice";
import { ApiError, OpenAPI } from "../services/frost-si8-services";

export function useFrostSi8Api(): {
  dismissError: () => void;
  error: ApiError | undefined;
  isLoading: boolean;
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>;
} {
  const token = useSelector(selectToken);
  const [error, setError] = useState<ApiError | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  OpenAPI.BASE = window.__RUNTIME_CONFIG__?.VITE_BASE_URL ?? "";
  OpenAPI.TOKEN = token?.toString();

  const handleRequest = useCallback(async function <T>(request: Promise<T>) {
    setIsLoading(true);
    try {
      const response = await request;
      setError(undefined);
      return response;
    } catch (exception: any) {
      setError(exception);
      if (exception.status === 401) {
        return;
      } else if (exception?.body !== "") {
        toast.error(exception?.body?.error?.message);
      } else if (exception?.message === "Forbidden") {
        toast.error("Your request is Forbidden!");
      } else if (exception.message === "Network Error") {
        toast.error("Unable to fetch api");
      } else if (exception?.body?.error?.validationErrors !== null) {
        toast.error("Your request is not valid!");
      } else if (exception.message !== null) {
        toast.error(exception.message);
      } else {
        toast.error("Unable to fetch api");
      }
      throw new Error(exception);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const dismissError = (): void => {
    setError(undefined);
  };

  return { dismissError, error, isLoading, handleRequest };
}

export default useFrostSi8Api;
