import { useQuery, useQueryClient, type UseQueryResult } from "react-query";
import {
  FSi8_Dtos_ResponseDtos_ResponseDto_1OfOfList_1OfOfFSi8_Dtos_Notifications_NotificationResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null,
  NotificationService,
} from "../../services/frost-si8-services";

const useGetAllBadgeNotification = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  | FSi8_Dtos_ResponseDtos_ResponseDto_1OfOfList_1OfOfFSi8_Dtos_Notifications_NotificationResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null
  | undefined,
  unknown
> => {
  const queryClient = useQueryClient();
  const allBadgeNotification = useQuery(
    ["getAllBadgeNotification"],
    async () => {
      return await handleRequest(
        NotificationService.getCoreApiApiAppNotificationBadgeNotificationForCurrentUser()
      );
    },
    {
      retry: false,
      onError: (error: any) => {
        if (error.response?.status === 503) {
          queryClient.invalidateQueries("getAllPopupNotification");
        }
      },
    }
  );

  return allBadgeNotification;
};
export default useGetAllBadgeNotification;
