import { lazy } from "react";

export const RouteList = [
  {
    path: "/",
    component: lazy(async () => await import("../pages/home/home")),
    exact: true,
  },

  {
    path: "/user-management",
    component: lazy(async () => await import("../pages/user/index")),
    exact: true,
  },

  {
    path: "/feedback",
    component: lazy(async () => await import("../pages/feedback/index")),
    exact: true,
  },
  {
    path: "/update",
    component: lazy(async () => await import("../pages/update/index")),
    exact: true,
  },

  // Page Not Found
  {
    path: "/page-not-found",
    component: lazy(async () => await import("../pages/pageNotFound")),
    exact: true,
  },
];
