/* eslint-disable no-unused-vars */
import { Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import { RouteList } from "./routesList";
import { selectIsAuthenticated } from "../pages/auth/authSlice";
import AuthService from "../services/authService";
import { useSelector } from "react-redux";
import Callback from "../pages/auth/callback";
import Login from "../pages/auth/login";

const RoutesContainer = () => {
  const authService = new AuthService();
  const isAuthenticated =
    useSelector(selectIsAuthenticated) || authService.isAuthenticated();

  return (
    <Suspense>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/callback" element={<Callback />} />
        <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} />}>
          {RouteList.map((route, i) => (
            <Route path={route.path} element={<route.component />} key={i} />
          ))}
          <Route path="*" element={<Navigate to="/page-not-found" replace />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default RoutesContainer;
