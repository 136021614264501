/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FSi8_Dtos_Notifications_UpdateNotificationStatusDto } from '../models/FSi8_Dtos_Notifications_UpdateNotificationStatusDto';
import type { FSi8_Dtos_ResponseDtos_ResponseDto_1OfOfList_1OfOfFSi8_Dtos_Notifications_NotificationResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/FSi8_Dtos_ResponseDtos_ResponseDto_1OfOfList_1OfOfFSi8_Dtos_Notifications_NotificationResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { FSi8_Dtos_ResponseDtos_ResponseDto_1OfOfSystem_String } from '../models/FSi8_Dtos_ResponseDtos_ResponseDto_1OfOfSystem_String';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class NotificationService {

    /**
     * @returns FSi8_Dtos_ResponseDtos_ResponseDto_1OfOfList_1OfOfFSi8_Dtos_Notifications_NotificationResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getCoreApiApiAppNotificationPopupNotificationForCurrentUser(): CancelablePromise<FSi8_Dtos_ResponseDtos_ResponseDto_1OfOfList_1OfOfFSi8_Dtos_Notifications_NotificationResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/core-api/api/app/notification/popup-notification-for-current-user',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns FSi8_Dtos_ResponseDtos_ResponseDto_1OfOfList_1OfOfFSi8_Dtos_Notifications_NotificationResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getCoreApiApiAppNotificationBadgeNotificationForCurrentUser(): CancelablePromise<FSi8_Dtos_ResponseDtos_ResponseDto_1OfOfList_1OfOfFSi8_Dtos_Notifications_NotificationResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/core-api/api/app/notification/badge-notification-for-current-user',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns FSi8_Dtos_ResponseDtos_ResponseDto_1OfOfSystem_String Success
     * @throws ApiError
     */
    public static postCoreApiApiAppNotificationMarkAllNotificationAsRead(
requestBody?: Array<string>,
): CancelablePromise<FSi8_Dtos_ResponseDtos_ResponseDto_1OfOfSystem_String> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/core-api/api/app/notification/mark-all-notification-as-read',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns FSi8_Dtos_ResponseDtos_ResponseDto_1OfOfSystem_String Success
     * @throws ApiError
     */
    public static putCoreApiApiAppNotificationIsPopupOpenedAndIsSeenStatusAysnc(
requestBody?: Array<FSi8_Dtos_Notifications_UpdateNotificationStatusDto>,
): CancelablePromise<FSi8_Dtos_ResponseDtos_ResponseDto_1OfOfSystem_String> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/core-api/api/app/notification/is-popup-opened-and-is-seen-status-aysnc',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
