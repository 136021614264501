import {
  useMutation,
  useQueryClient,
  type UseMutationResult,
} from "react-query";
import { toast } from "react-toastify";
import {
  FSi8_Dtos_ResponseDtos_ResponseDto_1OfOfSystem_String,
  NotificationService,
} from "../../services/frost-si8-services";

const usePostMarkAllRead = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseMutationResult<
  FSi8_Dtos_ResponseDtos_ResponseDto_1OfOfSystem_String | undefined,
  unknown,
  any,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    async (payload: Array<string>) => {
      return await handleRequest(
        NotificationService.postCoreApiApiAppNotificationMarkAllNotificationAsRead(
          payload
        )
      );
    },
    {
      onSuccess: async (
        response:
          | FSi8_Dtos_ResponseDtos_ResponseDto_1OfOfSystem_String
          | undefined
      ) => {
        if (response != null) {
          await queryClient.invalidateQueries("getAllBadgeNotification");
          toast.success("Marked As Read");
        }
      },
      onError: (err) => {
        console.log("error", err);
      },
    }
  );
};

export default usePostMarkAllRead;
