import styled from "styled-components";
import { colors } from "../../constants/themeConstants";

export const FooterWrapper = styled.footer`
  background: ${colors.secondary};
  color: ${colors.white};
  display: block;
  font-size: 9px;
  height: auto;
  // height: 1%;
  line-height: 0px;
  overflow: hidden;
  text-align: left;
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 99;
  @media (min-width: 768px) {
    font-size: 18px;
  }
  @media (min-width: 1440px) {
    font-size: 16px;
  }

  .container {
    flex-wrap: wrap;
    max-width: none;
    padding-bottom: 5px;
    padding-top: 5px;
    @media (min-width: 768px) {
      padding-bottom: 5px;
      padding-top: 5px;
    }
  }
  .footer-nav {
    margin: 0 -6px;
    padding: 0;
    // display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    &-start {
      padding: 3px;
      display: flex;
      line-height: 18px;
      justify-content: space-between;
      align-items: center;
      margin: -5px;

      .contact-us {
        display: flex;
        align-items: center;
      }

      .contact-us .material-icons {
        margin-right: 8px;
      }
    }
    &-end {
      display: flex;
      color:yellow;
      align-content: center;
      align-items: center;
      margin: -5px;
    }
  }
`;
