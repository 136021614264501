import {
  useMutation,
  useQueryClient,
  type UseMutationResult,
} from "react-query";
import {
  FSi8_Dtos_ResponseDtos_ResponseDto_1OfOfSystem_String,
  NotificationService,
} from "../../services/frost-si8-services";
import { toast } from "react-toastify";

const usePutNotifStatusChange = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseMutationResult<
  FSi8_Dtos_ResponseDtos_ResponseDto_1OfOfSystem_String | undefined,
  unknown,
  any,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    async (payload) => {
      return await handleRequest(
        NotificationService.putCoreApiApiAppNotificationIsPopupOpenedAndIsSeenStatusAysnc(
          payload
        )
      );
    },
    {
      onSuccess: async (response) => {
        if (response != null) {
          await queryClient.invalidateQueries("getAllBadgeNotification");
          await queryClient.invalidateQueries("getAllPopupNotification");
          // closeModal();
        }
      },
      onError: (err) => {
        console.log("error", err);
      },
    }
  );
};

export default usePutNotifStatusChange;
